/* Control the left side */
.app-split-layout-left {
  left: 0;
  margin: 0;
  padding: 0;
}
/* Control the right side */
.app-split-layout-right {
  right: 0;
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow-y: auto;
}
/* If you want the content centered horizontally and vertically */
.app-split-layout .app-split-layout-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.app-split-layout-rightpane {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
@media only screen and (min-width: 992px) {
  .app-split-layout > .app-split-layout-left {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 992px) {
  /* Control the left side */
  /* Control the right side */
  .app-split-layout {
    height: 100%;
    width: 100%;
    position: relative;
    display: inline-block;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    border-radius: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    
  }
}
