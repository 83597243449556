.map-container {
  flex: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 1024px) {
  .map-container {
    width: 100%;
    
  }
}
