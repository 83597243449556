.brand-pane-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 1rem;
}
.brand-pane-footer {
  height: 50vh;
  align-self: end;
  width: 100%;
}

.brand-pane {
   color: black; 
  background: #ffffff; 
  background-image: url("/assets/img/city.svg");
  background-position: left bottom 2rem;
  background-size: contain;
  background-repeat: no-repeat;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 1rem;
  border-radius: 1rem;
}
@media (max-width: 1024px) {
  .brand-pane {
    background-image: none;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
