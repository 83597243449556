.bg-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
}

.ambient-default {
  background: linear-gradient(
    rgba(0, 0, 0, 0.9) 10%,
    rgba(1, 9, 24, 0.877) 50%
  );
}
.ambient-night {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    rgba(3, 38, 85, 0.897),
    rgba(0, 1, 20, 0.877) 100%
  );
}

.ambient-day {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    rgba(12, 84, 179, 0.7),
    rgba(255, 255, 255, 0.9) 80%
  );
}
.main-pane {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 70%;
}


