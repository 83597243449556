#Clouds {
  position: absolute;
  opacity: 0.4;
  right: 0;
  top: 2rem;
  left: 0;
  margin: auto;
  height: 50px;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@-webkit-keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.Cloud {
  position: absolute;
  width: 100%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 70px;
  -webkit-animation-duration: 120s;
  animation-duration: 120s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: Float, FadeFloat;
  animation-name: Float, FadeFloat;
  z-index: 1;
}
.Cloud.Foreground {
  height: 20%;
  min-height: 20px;
  z-index: 0;
}
.Cloud.Background {
  height: 9.09090909%;
  min-height: 8px;
  -webkit-animation-duration: 210s;
  animation-duration: 210s;
}
@-webkit-keyframes Float {
  from {
    -webkit-transform: translateX(100%) translateZ(0);
    transform: translateX(100%) translateZ(0);
  }
  to {
    -webkit-transform: translateX(-15%) translateZ(0);
    transform: translateX(-15%) translateZ(0);
  }
}
@keyframes Float {
  from {
    -webkit-transform: translateX(100%) translateZ(0);
    transform: translateX(100%) translateZ(0);
  }
  to {
    -webkit-transform: translateX(-15%) translateZ(0);
    transform: translateX(-15%) translateZ(0);
  }
}
/* @keyframes Float {
    from {
        transform: translateX(100%) translateY(-100%) translateZ(0);
   }
    50% {
        transform: translateX(55%) translateY(0) translateZ(0);
   }
    to {
        transform: translateX(-5%) translateY(-100%) translateZ(0);
   }
}
*/
@-webkit-keyframes FadeFloat {
  0%,
  100% {
    opacity: 0;
  }
  5%,
  90% {
    opacity: 1;
  }
}
@keyframes FadeFloat {
  0%,
  100% {
    opacity: 0;
  }
  5%,
  90% {
    opacity: 1;
  }
}
.Cloud:nth-child(10) {
  -webkit-animation-delay: -184.61538462s;
  animation-delay: -184.61538462s;
  top: 60%;
}
.Cloud.Foreground:nth-child(10) {
  -webkit-animation-duration: 80s;
  animation-duration: 80s;
  height: 35%;
}
.Cloud.Background:nth-child(10) {
  -webkit-animation-duration: 110s;
  animation-duration: 110s;
  height: -3.40909091%;
}
.Cloud:nth-child(9) {
  -webkit-animation-delay: -166.15384615s;
  animation-delay: -166.15384615s;
  top: 54%;
}
.Cloud.Foreground:nth-child(9) {
  -webkit-animation-duration: 84s;
  animation-duration: 84s;
  height: 32.5%;
}
.Cloud.Background:nth-child(9) {
  -webkit-animation-duration: 114s;
  animation-duration: 114s;
  height: -2.15909091%;
}
.Cloud:nth-child(8) {
  -webkit-animation-delay: -147.69230769s;
  animation-delay: -147.69230769s;
  top: 48%;
}
.Cloud.Foreground:nth-child(8) {
  -webkit-animation-duration: 88s;
  animation-duration: 88s;
  height: 30%;
}
.Cloud.Background:nth-child(8) {
  -webkit-animation-duration: 118s;
  animation-duration: 118s;
  height: -0.90909091%;
}
.Cloud:nth-child(7) {
  -webkit-animation-delay: -129.23076923s;
  animation-delay: -129.23076923s;
  top: 42%;
}
.Cloud.Foreground:nth-child(7) {
  -webkit-animation-duration: 92s;
  animation-duration: 92s;
  height: 27.5%;
}
.Cloud.Background:nth-child(7) {
  -webkit-animation-duration: 122s;
  animation-duration: 122s;
  height: 0.34090909%;
}
.Cloud:nth-child(6) {
  -webkit-animation-delay: -110.76923077s;
  animation-delay: -110.76923077s;
  top: 36%;
}
.Cloud.Foreground:nth-child(6) {
  -webkit-animation-duration: 96s;
  animation-duration: 96s;
  height: 25%;
}
.Cloud.Background:nth-child(6) {
  -webkit-animation-duration: 126s;
  animation-duration: 126s;
  height: 1.59090909%;
}
.Cloud:nth-child(5) {
  -webkit-animation-delay: -92.30769231s;
  animation-delay: -92.30769231s;
  top: 30%;
}
.Cloud.Foreground:nth-child(5) {
  -webkit-animation-duration: 100s;
  animation-duration: 100s;
  height: 22.5%;
}
.Cloud.Background:nth-child(5) {
  -webkit-animation-duration: 130s;
  animation-duration: 130s;
  height: 2.84090909%;
}
.Cloud:nth-child(4) {
  -webkit-animation-delay: -73.84615385s;
  animation-delay: -73.84615385s;
  top: 24%;
}
.Cloud.Foreground:nth-child(4) {
  -webkit-animation-duration: 104s;
  animation-duration: 104s;
  height: 20%;
}
.Cloud.Background:nth-child(4) {
  -webkit-animation-duration: 134s;
  animation-duration: 134s;
  height: 4.09090909%;
}
.Cloud:nth-child(3) {
  -webkit-animation-delay: -55.38461538s;
  animation-delay: -55.38461538s;
  top: 18%;
}
.Cloud.Foreground:nth-child(3) {
  -webkit-animation-duration: 108s;
  animation-duration: 108s;
  height: 17.5%;
}
.Cloud.Background:nth-child(3) {
  -webkit-animation-duration: 138s;
  animation-duration: 138s;
  height: 5.34090909%;
}
.Cloud:nth-child(2) {
  -webkit-animation-delay: -36.92307692s;
  animation-delay: -36.92307692s;
  top: 12%;
}
.Cloud.Foreground:nth-child(2) {
  -webkit-animation-duration: 112s;
  animation-duration: 112s;
  height: 15%;
}
.Cloud.Background:nth-child(2) {
  -webkit-animation-duration: 142s;
  animation-duration: 142s;
  height: 6.59090909%;
}
.Cloud:nth-child(1) {
  -webkit-animation-delay: -18.46153846s;
  animation-delay: -18.46153846s;
  top: 6%;
}
.Cloud.Foreground:nth-child(1) {
  -webkit-animation-duration: 116s;
  animation-duration: 116s;
  height: 12.5%;
}
.Cloud.Background:nth-child(1) {
  -webkit-animation-duration: 146s;
  animation-duration: 146s;
  height: 7.84090909%;
}
.Cloud {
  background-image: url("/assets/img/cloud.svg");
}
.Cloud.Background {
  background-image: url("/assets/img/cloud.svg");
}
